import classNames from 'classnames/bind'
import React from 'react'
import * as styles from './style'
const cx = classNames.bind(styles)

export const availableIcons = [
  'alarm',
  'arrow-left-black',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'art',
  'bell',
  'book-filled',
  'bookmark',
  'briefcase',
  'calendar-black',
  'calendar-blue',
  'calendar',
  'camera',
  'cancel',
  'cards',
  'chat',
  'checkbox',
  'checks',
  'chevron-black-down',
  'chevron-black-up',
  'chevron-right',
  'clipboard',
  'clock',
  'close-blue-slim',
  'close-grey',
  'close',
  'closing-quote',
  'community',
  'doctor',
  'dots',
  'double-tick',
  'education',
  'facebook-filled',
  'facebook',
  'filter',
  'filters-black',
  'filters-blue',
  'funnel',
  'gift',
  'heart',
  'help',
  'home-active',
  'home',
  'house',
  'id',
  'kitchen',
  'lang',
  'learn-active',
  'learn',
  'location',
  'man-woman',
  'map',
  'messenger',
  'mind',
  'moon',
  'outlook-calendar',
  'paper',
  'payment',
  'pencil',
  'people-chat',
  'people-group',
  'people',
  'person-plus',
  'person',
  'phone-filled',
  'phone-support',
  'phone',
  'pin-filled',
  'pin',
  'play',
  'playlist',
  'plus',
  'profile-active',
  'profile',
  'reaction-clap',
  'reaction-heart-eyes',
  'reaction-heart-face',
  'reaction-heart',
  'reaction-joy',
  'reaction-raised-hands',
  'reaction-thumbs-up',
  'reaction-wow',
  'reset-black',
  'reset',
  'retry',
  'search',
  'share',
  'sort',
  'spinner',
  'star-filled',
  'star-outline',
  'sun-down',
  'sun-up',
  'sun',
  'tick-small',
  'tick',
  'trend-up',
  'triangle-left',
  'triangle-right',
  'yahoo-calendar',
  'youtube',
] as const
export type ValidIcon = (typeof availableIcons)[number]

export interface Props {
  name: ValidIcon
  color?: string
  size?: number
  className?: string
  rawColors?: boolean
  align?: 'sub' | 'super' | 'text-bottom' | 'text-top' | 'middle' | 'top' | 'bottom' | 'middle' | 'revert'
}

interface Style {
  background?: string
  backgroundImage?: string
  WebkitMaskImage?: string
  maskImage?: string
  height: string
  width: string
}

interface FrameProps {
  children?: React.ReactNode[] | JSX.Element
  theme?: 'light' | 'dark'
}

export const IconFrame = ({ children, theme = 'light' }: FrameProps): JSX.Element => {
  const classes = cx({
    frame: true,
    [styles[`frame_${theme}`]]: true,
  })

  return (
    <div data-testid="icon-frame" className={classes}>
      {children}
    </div>
  )
}

const Icon = ({ color, name, size, className = '', rawColors = false, align = 'revert' }: Props): JSX.Element => {
  const imageUrl = `url(/icons/${name}.svg)`
  const classes = cx({
    root: true,
    rawColors,
    [className]: true,
  })
  let styleOverrides: Partial<Style> = rawColors
    ? {
        backgroundImage: `${imageUrl}`,
      }
    : {
        background: color || 'currentColor',
        WebkitMaskImage: imageUrl,
        maskImage: imageUrl,
      }

  if (size) {
    styleOverrides.height = `${size}px`
    styleOverrides.width = `${size}px`
  }

  return <i data-testid={`icon-${name}`} className={classes} style={{ ...styleOverrides, verticalAlign: align }}></i>
}
export default Icon
