import { RBP } from '../../constants/measured-scope';
export const root = "r1jskws0";
export const size_xxxs = "s171q37u";
export const size_xxs = "s92m8m2";
export const size_xs = "sizvnh5";
export const size_sm = "s1jbg45r";
export const size_md = "sj4tg5j";
export const size_lg = "s8p238q";
export const size_xl = "sz5ppqr";
export const size_xxl = "s1tn7jie";
export const size_xxxl = "sp3cbym";
export const weight_sm = "w1lxqbje";
export const weight_md = "wzx3y84";
/**
 * Font Weight: Semi-bold, 600
 */
export const weight_sb = "w1p6rejs";
export const weight_lg = "wsnbjxd";
export const uppercase = "uwgylco";

// Create global responsive utility classes for font size & line-height
// adjustments. Must exported as global CSS classes since Linaria doesn't
// like generative scoped styles

"s1pjvehs";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");