import { getConfig } from '@/lib/config'
import { isValidLocale, storeLocaleCookie } from '@/lib/languages'
import { getClientIdentityForQuery } from '@/service/request'
import { IncomingMessage } from 'http'
import { SSRData, SSRExchange } from 'next-urql'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import { cacheExchange, Client, createClient, dedupExchange, fetchExchange, ssrExchange } from 'urql'

const { publicRuntimeConfig } = getConfig()
const url = publicRuntimeConfig.graphqlApi.url

let urqlClient: Client | null = null
let ssrCache: ReturnType<typeof ssrExchange> | null = null
const isServer = typeof window === 'undefined'

type NextJSRequest = IncomingMessage & {
  cookies: NextApiRequestCookies
}

interface InitUrqlClientOptions {
  initialState?: SSRData
  req?: NextJSRequest
  locale?: string
}

// Function to initialize urql client.
// Can be used both on client and server
export function initUrqlClient({ initialState, req, locale }: InitUrqlClientOptions): {
  urqlClient: Client
  ssrCache: SSRExchange | null
} {
  if (isValidLocale(locale) && !isServer) storeLocaleCookie(locale)

  // ⚠ We need to reinitialize the client on every server request to avoid losing the headers.
  if (!urqlClient || req || isServer) {
    // Fill the client with initial state from the server.
    ssrCache = ssrExchange({ initialState, isClient: !isServer })

    urqlClient = createClient({
      url,
      exchanges: [
        dedupExchange,
        cacheExchange,
        ssrCache, // Add `ssr` in front of the `fetchExchange`
        fetchExchange,
      ],
      requestPolicy: 'cache-and-network',
      fetchOptions: {
        credentials: 'include',
        headers: composeHeadersForFetchRequest(req, locale),
      },
    })
  } else if (initialState) {
    // When navigating to another page, client is already initialized.
    // Let's restore that page's initial state
    ssrCache?.restoreData(initialState)
  }

  // Return both the Client instance and the ssrCache.
  return { urqlClient, ssrCache }
}

function composeHeadersForFetchRequest(req?: NextJSRequest, locale?: string): HeadersInit {
  const headers: HeadersInit = {
    'apollographql-client-name': 'gsu-web',
    'apollographql-client-version': publicRuntimeConfig.environment, // TODO: get git sha?
  }

  if (req) {
    const { ip: clientIpAddress } = getClientIdentityForQuery(req)

    if (clientIpAddress) {
      headers['x-forwarded-for'] = clientIpAddress
    }

    // Pass on all cookies to the back-end so that we don't need to explicitly define each one.
    if (req.cookies) {
      headers['Cookie'] = buildCookieString(req.cookies)
    }
  }

  // If a locale is provided, we need to set the NEXT_LOCALE cookie so that the back-end can
  // determine the correct language to use.
  if (locale) {
    headers['Cookie'] = `NEXT_LOCALE=${locale};${headers['Cookie'] || ''}`
  }

  return headers
}

const buildCookieString = (cookies: NextApiRequestCookies): string => {
  return Object.entries(cookies)
    .map(([key, value]) => `${key}=${value}`)
    .join(';')
}
