import * as React from 'react'

type Dispatch = (action: Action) => void
type ProviderProps = { initialState?: State; children: React.ReactNode }
type Action = { type: 'TOGGLE' | 'DISMISS' }
type State = { isOpen?: boolean }
interface ContextInterface {
  state: State
  dispatch: Dispatch
}

const Context = React.createContext<ContextInterface | undefined>(undefined)

const initialStateFallback: State = { isOpen: false }

function reducer(state: State, action: Action): Partial<State> {
  switch (action.type) {
    case 'TOGGLE': {
      return { ...state, isOpen: !state.isOpen }
    }
    case 'DISMISS': {
      return { ...state, isOpen: false }
    }
  }
}

function Provider({ initialState, children }: ProviderProps): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, initialState || initialStateFallback)
  const value = { state, dispatch }
  return <Context.Provider value={value}>{children}</Context.Provider>
}
const ModalProvider = Provider

function useModal(): ContextInterface {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error('useModal must be used within a Provider')
  }
  return context
}
export { ModalProvider, useModal }
