export const root = "r1l0hw9f";
export const actions = "a1t2jqun";
export const content = "c6qmkwh";
export const contentStyling = "cennnlh";
export const contentDisabledStyling = "c67tz9g";
export const close = "csv3e5a";
export const closeBackground = "c469hhm";
export const closeButton = "cxljern";
export const closeLabel = "colmzyr";
export const closeLabelWhite = "c1lvg4nr";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");