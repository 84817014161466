export const customClass = "c1hx36j3";
export const container = "cf280a9";
export const buttonContainer = "bm18f0y";
export const imageContainer = "i1huaxys";
export const heading = "h1ydfyk1";
export const subheading = "s11ujptw";
export const separator = "snjfnrh";
export const orText = "o1uc5se1";
export const button = "blgrabo";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");