import { ResponsiveSize, UnitSize } from '@/constants/measured-scope'
import classNames from 'classnames/bind'
import React from 'react'
import * as styles from './style'
const cx = classNames.bind(styles)

type FlexAlign =
  | 'unset'
  | 'flex-start'
  | 'flex-end'
  | 'auto'
  | 'baseline'
  | 'center'
  | 'end'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'self-start'
  | 'self-end'
  | 'normal'
  | 'start'
  | 'stretch'

type FlexJustify =
  | 'center'
  | 'start'
  | 'end'
  | 'flex-start'
  | 'flex-end'
  | 'left'
  | 'right'
  | 'normal'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch'
  | 'safe center'
  | 'unsafe center'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset'

export interface Props {
  spacing?: UnitSize
  className?: string
  children: React.ReactNode | React.ReactNode[]
}

interface StackSizes {
  x?: ResponsiveSize[] | boolean
  y?: ResponsiveSize[] | boolean
}

interface StackProps extends StackSizes {
  alignSelf?: FlexAlign
  alignItems?: FlexAlign
  spaceBetweenX?: UnitSize
  spaceBetweenY?: UnitSize
  justifyContent?: FlexJustify
  className?: string
  children?: React.ReactNode | React.ReactNode[]
  tag?: keyof JSX.IntrinsicElements
}

type ClassNames = typeof styles
type PartialClassNames = {
  [property in keyof ClassNames | string]?: boolean
}

export const Stack = ({
  x,
  y,
  className,
  children,
  alignSelf,
  alignItems,
  spaceBetweenX,
  spaceBetweenY,
  justifyContent,
  tag: Tag = 'div',
  ...rest
}: StackProps): JSX.Element => {
  let styleOverrides: Partial<StackProps> = {}

  if (alignSelf) styleOverrides.alignSelf = alignSelf
  if (alignItems) styleOverrides.alignItems = alignItems
  if (justifyContent) styleOverrides.justifyContent = justifyContent

  const getSpaceBetweenClassValues = ({ spaceBetweenX, spaceBetweenY }: Partial<StackProps>): PartialClassNames => {
    let cxPartial: PartialClassNames = {}
    if (spaceBetweenX) cxPartial[styles[`space_between_x_${spaceBetweenX}`]] = true
    if (spaceBetweenY) cxPartial[styles[`space_between_y_${spaceBetweenY}`]] = true
    return cxPartial
  }

  const getStackClassValues = ({ x, y }: StackSizes): PartialClassNames => {
    let cxPartial = {
      stackX: !!x,
      stackY: !!y,
    }
    return cxPartial
  }

  const classes = cx({
    stack: true,
    [`${className ? className : ''}`]: true,
    ...getStackClassValues({ x, y }),
    ...getSpaceBetweenClassValues({ spaceBetweenX, spaceBetweenY }),
  })
  return (
    <Tag {...rest} className={classes} style={styleOverrides}>
      {children}
    </Tag>
  )
}

const Layout = ({ spacing, className, children, ...rest }: Props): JSX.Element => {
  const getLayoutClassValues = ({ spacing }: Partial<Props>): PartialClassNames => {
    let cxPartial: PartialClassNames = {}
    if (!spacing) return cxPartial
    cxPartial[styles[`spacing_${spacing}`]] = true
    return cxPartial
  }

  const classes = cx({
    root: true,
    [`${className}`]: true,
    ...getLayoutClassValues({ spacing }),
  })
  return (
    <div {...rest} className={classes}>
      {children}
    </div>
  )
}
export default Layout
