import React, { useEffect } from 'react'
import { gql, useQuery } from 'urql'

type ProviderProps = { children: React.ReactNode }
type FeatureToggleContext = Record<string, boolean>

const Context = React.createContext<FeatureToggleContext | undefined>(undefined)

export const QUERY_FEATURE_TOGGLES = gql`
  query FeatureToggles {
    features(currentVersion: "", appName: "gsu-web") {
      id
      enabled
    }
  }
`

export function FeatureToggleProvider({ children }: ProviderProps): JSX.Element {
  const [featureToggles, setFeatureToggles] = React.useState({})
  const [
    {
      data,
      // fetching
    },
  ] = useQuery({ query: QUERY_FEATURE_TOGGLES })

  const features: FeatureToggleContext = {}

  useEffect(() => {
    if (data?.features) {
      data.features.forEach((element: { id: string; enabled: boolean }) => {
        features[element.id] = element.enabled
      })
    }

    setFeatureToggles(features)
  }, [data])

  return <Context.Provider value={featureToggles}>{children}</Context.Provider>
}

/**
 * A hook for querying the state of a feature toggle.
 * @param featureId The Id of the feature as set up in ConfigCat. Probably a human readable string. E.g. `red_header_on_home_page`
 * @returns A boolean indicating whether this feature toggle is enabled for this user.
 */
export function useFeatureToggle(featureId: string): boolean {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error('useFeatureToggles must be used within a FeatureToggleProvider')
  }
  return context[featureId] ?? false
}
