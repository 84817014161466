import { getConfig } from '@/lib/config'
import { dateWithFormat } from '@/lib/datetime'

export const getInitialTimeSliderHours = (): {
  start: number
  end: number
} => {
  const { publicRuntimeConfig } = getConfig()
  const currentHour = parseInt(dateWithFormat(new Date(), 'H'))

  if (
    currentHour >= publicRuntimeConfig.calendarPageSlider.initialSessionsIntervalStart &&
    currentHour < publicRuntimeConfig.calendarPageSlider.initialSessionsIntervalEnd
  )
    return {
      start: publicRuntimeConfig.calendarPageSlider.initialSessionsIntervalStart,
      end: publicRuntimeConfig.calendarPageSlider.initialSessionsIntervalEnd,
    }
  return {
    start: 0,
    end: 24,
  }
}
