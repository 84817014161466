import { ReactNode, useEffect, useState } from 'react'
import { Provider } from 'urql'
import { useClient } from './useClient'

type Props = {
  children: ReactNode
  pageProps: any
}

/**
 * @param props If `props` includes `enableServerSideHtml: true` then the HTML of the page will be rendered server side. Otherwise, only references to the JS will be included in the HTML and the page will render client side only.
 */
const UrqlProvider = ({ children, pageProps }: Props): JSX.Element | null => {
  const client = useClient(pageProps)
  const [mounted, setMounted] = useState(false)

  // useEffect only runs on the client, so now we can safely show the UI
  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted && !pageProps.enableServerSideHtml) {
    return null
  }

  return <Provider value={client}>{children}</Provider>
}
export default UrqlProvider
