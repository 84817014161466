import { useFeatureToggle } from '@/context/featureToggles'
import {
  LocationBase,
  MetaInfo,
  MetaInfoSchema,
  PotentialActionSchema,
  SchemaProvider,
} from '@/shared/api-generated-types'
import Head from 'next/head'

type PotentialAction = Partial<PotentialActionSchema> & {
  '@type'?: string
  'query-input'?: string
}

type Provider = Partial<SchemaProvider> & {
  '@type'?: string
}
type Location = Partial<LocationBase> & {
  '@type'?: string
}

type JsonLd = Partial<Omit<MetaInfoSchema, 'potentialAction'>> & {
  '@context': string
  '@type': string
  potentialAction?: PotentialAction
  provider?: Provider
  sameAs?: string[]
  location?: Location
}

export function formatJsonLd(data: MetaInfoSchema): string {
  const jsonLd: JsonLd = { '@context': data.context, '@type': data.type, ...data }

  if (data.__typename === 'HomeSchema') {
    if (data.potentialAction) {
      jsonLd.potentialAction = {
        '@type': data.potentialAction.type,
        target: data.potentialAction.target,
        'query-input': data.potentialAction.queryInput,
      }
    }

    if (!data.sameAs) delete jsonLd.sameAs
  }

  if (data.__typename === 'ClassSchema') {
    if (data.provider) {
      jsonLd.provider = {
        '@type': data.provider.type,
        name: data.provider.name,
      }
    }
  }

  if (data.__typename === 'SessionSchema' && data.location) {
    jsonLd.location = {
      '@type': data.location.type,
      url: data.location.url,
    }
  }

  // Delete unused properties
  delete jsonLd.__typename
  delete jsonLd.context
  delete jsonLd.type

  return JSON.stringify(jsonLd)
}

export const GlobalMetaTags = ({ title, description, image, robots }: Partial<MetaInfo>): JSX.Element => {
  const canTestNewViewport = useFeatureToggle('eng_user_scalability_testing')
  return (
    <Head>
      {title ? <title>{title}</title> : null}
      <meta name="description" content={description} />
      {canTestNewViewport ? (
        <meta name="viewport" content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0" />
      ) : (
        <meta
          name="viewport"
          content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      )}

      {robots ? <meta name="robots" content={robots} /> : null}

      {/* Fav/Touch icons */}
      <link rel="apple-touch-icon" sizes="180x180" href="/images/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon-16x16.png" />
      <link rel="manifest" href="/images/site.webmanifest" />
      <link rel="mask-icon" href="/images/safari-pinned-tab.svg" color="#ff4400" />
      <link rel="shortcut icon" href="/images/favicon.ico" />
      <link rel="icon" href="/images/favicon.ico" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-config" content="/images/browserconfig.xml" />

      {/* Facebook OpenGraph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image ? <meta property="og:image" content={image} /> : null}
      <meta property="og:site_name" content="GetSetUp" />
      <meta property="fb:pages" content="103936981215561" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@SetupGet" />
      <meta name="twitter:title" content={title} />
      {description ? <meta name="twitter:description" content={description} /> : null}
      {image ? <meta name="twitter:image" content={image} /> : null}

      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content="GetSetUp" />
      <meta name="author" content="GetSetUp" />
      <meta name="theme-color" content="#181b56" />
    </Head>
  )
}

export const PageMetaInfo = (props: MetaInfo): JSX.Element => {
  return (
    <>
      <GlobalMetaTags {...props} />
      {props.jsonLd ? (
        <Head>
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: formatJsonLd(props.jsonLd) }} />
        </Head>
      ) : null}
    </>
  )
}
