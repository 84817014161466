import { gql } from 'urql'

export const FragDialog = gql`
  fragment Dialog on Dialog {
    __typename
    id
    title
    supplementaryText
    lottieJsonUrl
    videoUrl
    videoPosterUrl
    primaryAction {
      ... on DialogUrlAction {
        url
        label
      }
      ... on DialogPageAction {
        targetPageId
        label
      }
      ... on DialogFacebookConnectAction {
        url
        label
      }
      ... on DialogDismissAction {
        label
      }
    }
    secondaryAction {
      ... on DialogUrlAction {
        url
        label
      }
      ... on DialogPageAction {
        targetPageId
        label
      }
      ... on DialogFacebookConnectAction {
        url
        label
      }
      ... on DialogDismissAction {
        label
      }
    }
    closable
    partnerLogoUrl
    callout {
      __typename
      icon
      content {
        __typename
        ... on RichTextTextNode {
          nodeType
          value
        }
        ... on RichTextLinkNode {
          nodeType
          value
          data {
            __typename
            ... on RichTextPageLinkNode {
              type
              linkType
              targetPageId
            }
            ... on RichTextHyperlinkLinkNode {
              type
              linkType
              value
            }
          }
        }
      }
    }
  }
`

export const QUERY_DIALOG = gql`
  ${FragDialog}
  query dialog($type: ID!, $returnTo: String) {
    dialog(type: $type, returnTo: $returnTo) {
      __typename
      ...Dialog
    }
  }
`
