import { Props } from '@/lib/cancelBooking'
import * as React from 'react'

export type Dispatch = (action: Action) => void
type ProviderProps = { children: React.ReactNode }

export const actions = {
  fetching: (sessionId: Props) => ({
    type: 'FETCHING',
    payload: { sessionId },
  }),
  booked: (sessionId: Props) => ({
    type: 'CANCELLED',
    payload: { sessionId },
  }),
  failed: (sessionId: Props) => ({
    type: 'FAILED',
    payload: { sessionId },
  }),
}
type Action = { type: 'FETCHING' | 'CANCELLED' | 'FAILED'; payload: Partial<Props> }

type State = {
  fetching?: Array<string | undefined>
  cancelled?: Array<string | undefined>
  failed?: Array<string | undefined>
}

export interface ContextInterface {
  state: State
  dispatch: Dispatch
}

const Context = React.createContext<ContextInterface | undefined>(undefined)

const initialState: State = { fetching: [], cancelled: [], failed: [] }

function reducer(state: State, action: Action): Partial<State> {
  const newState = state
  switch (action.type) {
    case 'FETCHING': {
      newState.failed = newState.fetching?.filter((e) => e !== action.payload.sessionId)
      newState.fetching?.push(action.payload.sessionId)
      return { ...newState }
    }
    case 'CANCELLED': {
      newState.failed = newState.fetching?.filter((e) => e !== action.payload.sessionId)
      newState.fetching = newState.fetching?.filter((e) => e !== action.payload.sessionId)
      newState.cancelled?.push(action.payload.sessionId)
      return { ...newState }
    }
    case 'FAILED': {
      newState.fetching = newState.fetching?.filter((e) => e !== action.payload.sessionId)
      newState.failed?.push(action.payload.sessionId)
      return { ...newState }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function Provider({ children }: ProviderProps): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const value = { state, dispatch, actions }
  return <Context.Provider value={value}>{children}</Context.Provider>
}
const CancelBookingProvider = Provider

function useCancelBooking(): ContextInterface {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error('useCancelBooking must be used within a Provider')
  }
  return context
}
export { CancelBookingProvider, useCancelBooking }
