function selectFirstIfMany(input: string | string[]): string {
  if (Array.isArray(input)) {
    return input[0]
  }
  return input
}

export function firstStringVal(input: string | string[] | undefined): string | undefined {
  if (!input) return undefined
  return selectFirstIfMany(input)
}
