import { AuthenticatedUser } from '@/shared/api-generated-types'
import { onAuthReset } from '@getsetup-io/html-embed'
import * as React from 'react'

type Dispatch = (action: Action) => void
type ProviderProps = { children: React.ReactNode }
type Action = { type: 'UPDATE'; payload: Partial<State> } | { type: 'LOGOUT'; payload?: { returnHome: boolean } }
type User = Partial<AuthenticatedUser> & { fullName: string }
type State = {
  user?: User | null
}
interface ContextInterface {
  state: State
  dispatch: Dispatch
}

const Context = React.createContext<ContextInterface | undefined>(undefined)

const initialState: State = {
  user: null,
}

const getFullName = (user: User | undefined | null): string => {
  if (!user) return ''
  const { firstName, lastName } = user
  return `${firstName}${lastName ? ' ' + lastName : ''}`
}

const persistedCookies = new Set(['NEXT_LOCALE'])

// Copied from Nuxt app
const clearCookies = (): void => {
  try {
    var cookies = document.cookie.split('; ')
    for (var c = 0; c < cookies.length; c++) {
      var cKey = encodeURIComponent(cookies[c].split(';')[0].split('=')[0])
      if (persistedCookies.has(cKey)) {
        continue
      }
      var d = window.location.hostname.split('.')
      while (d.length > 0) {
        var cookieBase = cKey + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path='
        var p = location.pathname.split('/')
        document.cookie = cookieBase + '/'
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/')
          p.pop()
        }
        d.shift()
      }
    }
  } catch (error) {
    console.error('clearCookies', error)
  }
}

// Copied from Nuxt app
const clearLocalStorage = (): void => {
  try {
    window.localStorage.clear()
    window.sessionStorage.clear()
  } catch (error) {
    console.error('clearLocalStorage', error)
  }
}
// Copied from Nuxt app

const clearSession = (returnHome?: boolean): void => {
  try {
    clearCookies()
    clearLocalStorage()
  } catch (error) {
    console.error('clearSession', error)
  } finally {
    setTimeout(() => {
      if (returnHome) {
        window.location.href = window.location.origin
      } else {
        window.location.href = window.location.origin + window.location.pathname
      }
    }, 300)
  }
}

function reducer(state: State, action: Action): Partial<State> {
  switch (action.type) {
    case 'UPDATE': {
      if (action.payload?.user === null) return state
      return {
        ...state,
        user: {
          ...action.payload.user,
          fullName: getFullName(action.payload?.user),
        },
      }
    }
    case 'LOGOUT': {
      clearSession(action.payload?.returnHome)
      onAuthReset()
      return {
        ...state,
        user: null,
      }
    }
  }
}

function Provider({ children }: ProviderProps): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const value = { state, dispatch }
  return <Context.Provider value={value}>{children}</Context.Provider>
}
const AuthProvider = Provider

function useAuth(): ContextInterface {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error('useAuth must be used within a Provider')
  }
  return context
}
export { AuthProvider, useAuth }
