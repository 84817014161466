import { getConfig } from '@/lib/config'

const { publicRuntimeConfig } = getConfig()
const BASE_URL = publicRuntimeConfig?.website.url || 'https://www.getsetup.io'

export function targetPageIdToUrl(targetPageId?: string): string {
  if (!targetPageId) return new URL(BASE_URL).toString()
  const pageId = targetPageId === 'home' ? '/' : targetPageId
  return new URL(pageId, BASE_URL).toString()
}
