import Button, { ButtonTheme } from '@/components/Button'
import IcoMoon from '@/components/IcoMoon'
import { Dialog } from '@headlessui/react'
import classNames from 'classnames/bind'
import React from 'react'
import * as styles from './style'
const cx = classNames.bind(styles)

export interface Action {
  label: string
  theme?: ButtonTheme
  stretch?: boolean
  onClick: () => void
}

export interface Props {
  actions?: Action[]
  className?: string
  onClose?: () => void
  isOpen?: boolean
  children: React.ReactNode | React.ReactNode[]
  onDismiss?: (event?: React.SyntheticEvent) => void
  ariaLabel?: string
  media?: boolean
  isClosable?: boolean
  unstyled?: boolean
  outsideCloseButton?: boolean
}

const Modal = ({
  actions,
  className = '',
  onDismiss,
  isOpen,
  children,
  ariaLabel = 'Attention!',
  media,
  isClosable = true,
  unstyled = false,
  outsideCloseButton = false,
}: Props): JSX.Element => {
  const contentClasses = cx({
    content: true,
    contentStyling: !unstyled,
    contentDisabledStyling: unstyled,
    media,
  })

  const closeButtonLabel = (
    <IcoMoon
      removeInlineStyle
      icon="close"
      className={cx({
        closeLabel: true,
        closeLabelWhite: outsideCloseButton,
      })}
    />
  )

  return (
    <Dialog className={styles.root} data-dialog-overlay open={isOpen ?? true} onClose={() => onDismiss}>
      <Dialog.Panel
        data-dialog-content
        aria-label={ariaLabel}
        className={`${contentClasses} ${className} ${unstyled ? '' : 'drop-shadow'}`}
      >
        {isClosable ? (
          <div
            className={cx({
              close: !outsideCloseButton,
              closeBackground: outsideCloseButton,
            })}
          >
            <Button
              onClick={onDismiss}
              theme="clear"
              textOnly
              title="Close dialog"
              label={closeButtonLabel}
              className={styles.closeButton}
            />
          </div>
        ) : null}
        {children}
        {!!actions?.length && (
          <div className={styles.actions}>
            {actions.map(({ label, theme, onClick, stretch }, i) => (
              <Button
                tabIndex={i}
                theme={theme || 'secondary'}
                stretch={stretch || false}
                label={label}
                onClick={onClick}
                size="lg"
              />
            ))}
          </div>
        )}
      </Dialog.Panel>
    </Dialog>
  )
}
export default Modal
