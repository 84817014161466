import { getConfig } from '@/lib/config'

const { publicRuntimeConfig } = getConfig()
const LOGIN_BASE_URL = publicRuntimeConfig?.auth.loginUrl || 'https://www.getsetup.io/auth'
const SIGN_UP_BASE_URL = publicRuntimeConfig?.auth.signUpUrl || 'https://www.getsetup.io/auth'

function toAuthUrl(baseUrl: string, returnToPath?: string): string {
  if (!returnToPath || returnToPath === '' || returnToPath === '/') {
    return baseUrl
  }

  const returnToUrl = new URL(returnToPath, window.location.origin)
  const authUrl = new URL(baseUrl)
  if (returnToUrl) authUrl.searchParams.set('returnTo', returnToUrl.toString())
  return authUrl.toString()
}

export function toLoginAuthUrl(returnToPath?: string): string {
  return toAuthUrl(LOGIN_BASE_URL, returnToPath)
}

export function toSignUpAuthUrl(returnToPath?: string): string {
  return toAuthUrl(SIGN_UP_BASE_URL, returnToPath)
}
