import type { RBP } from '@/constants/measured-scope'
import classNames from 'classnames/bind'
import Link from 'next/link'
import React, { ReactNode, useMemo } from 'react'
import * as styles from './style'
const cx = classNames.bind(styles)

export type Sizes = 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl'

/**
 * Small, medium, semi-bold, large.
 */
export type Weights = 'sm' | 'md' | 'sb' | 'lg'
export type ResponsiveSizeMap = {
  [key in RBP as string]: Sizes
}
type ClassNameMap = {
  [x: string]: boolean
}
export interface Props {
  size?: Sizes
  tag?: keyof JSX.IntrinsicElements
  weight?: Weights
  children: React.ReactNode
  className?: string
  uppercase?: boolean
  title?: string
  responsiveSize?: Partial<ResponsiveSizeMap>
}

const Text = ({
  size = 'sm',
  responsiveSize,
  tag: T = 'span',
  children,
  weight = 'md',
  className = '',
  uppercase,
  title = '',
}: Props): JSX.Element => {
  const responsiveSizeClasses = useMemo(
    () =>
      !!responsiveSize
        ? Object.keys(responsiveSize).reduce((acc: ClassNameMap, key) => {
            const value = responsiveSize[key]
            if (value) {
              acc[`text-rbp-${key}-size-${value}`] = true
            }
            return acc
          }, {})
        : {},
    [responsiveSize],
  )

  const classes = cx({
    root: true,
    [styles[`size_${size}`]]: true,
    [styles[`weight_${weight}`]]: true,
    [`${className}`]: true,
    uppercase,
    ...responsiveSizeClasses,
  })
  return (
    <T title={title} className={classes}>
      {children}
    </T>
  )
}
export default Text

/*
  These below named exports are just wrappers around the above <Text>
  You can see them in action at /terms-of-use and /privacy-policy pages
  These exist so we have an easy way to change the styles whenever we want
  and also we do not constantly repeat the same styles. It's just easier!
*/
export const Title: React.FC<Props> = ({ children, tag = 'h2', weight = 'sm', ...props }) => {
  let titleClassName = props.className ? 'pt pb-sm ' + props.className : 'pt pb-sm'
  return (
    <Text size="lg" tag={tag} weight={weight} className={titleClassName} {...props}>
      {children}
    </Text>
  )
}

export const Content: React.FC<{ bottomPadding?: boolean; tag?: keyof JSX.IntrinsicElements; children: ReactNode }> = ({
  bottomPadding = false,
  tag = 'p',
  children,
}) => {
  return (
    <Text tag={tag} size="xs" weight="sm" className={bottomPadding ? 'pb' : ''}>
      {children}
    </Text>
  )
}

export const LinkText: React.FC<{ href: string; children: ReactNode }> = ({ href, children }) => {
  return (
    <Text tag="span" size="xs" weight="sm" className="fc-secondary">
      <Link href={href}>{children}</Link>
    </Text>
  )
}

export const List: React.FC<{ listArray: (string | JSX.Element)[]; ordered?: boolean }> = ({ listArray, ordered }) => {
  return (
    <Text tag={!ordered ? 'ul' : 'ol'}>
      {listArray.map((item) => (
        <Text key={item.toString()} tag="li" size="xs" weight="sm" className="pb-sm">
          {item}
        </Text>
      ))}
    </Text>
  )
}
