import Text from '@/components/Text'
import { Callout } from '@/shared/api-generated-types'
import Link from 'next/link'
import * as styles from './style'

export interface Props {
  callout?: Callout
}

const RichText = ({ callout }: Props): JSX.Element => {
  return (
    <div>
      {callout?.content?.map((node, key) => {
        if (node.__typename === 'RichTextTextNode') {
          return (
            <Text key={key} size="xxs" weight="sm">
              {node.value}
            </Text>
          )
        } else if (node.__typename === 'RichTextLinkNode' && node.data.__typename === 'RichTextPageLinkNode') {
          return (
            <Link key={key} href={`/${node.data.targetPageId}`}>
              <Text size="xxs" weight="sm" className={styles.link}>
                {node.value}
              </Text>
            </Link>
          )
        } else if (node.__typename === 'RichTextLinkNode' && node.data.__typename === 'RichTextHyperlinkLinkNode') {
          return (
            <a key={key} href={node.data.value} className={styles.link}>
              <Text size="xxs" weight="sm">
                {node.value}
              </Text>
            </a>
          )
        }
      })}
    </div>
  )
}
export default RichText
