// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import GsuLoader from '@/components/GsuLoader';
import { getConfig } from '@/lib/config';
import { routerToFacebookLocale } from '@/lib/facebook';
const {
  publicRuntimeConfig: config
} = getConfig();
declare const window: Window & typeof globalThis & {
  FB: any;
  fbAsyncInit: any;
};
interface AuthResponse {
  authResponse: {
    accessToken: string;
    data_access_expiration_time: number;
    expiresIn: number;
    graphDomain: string;
    signedRequest: string;
    userID: string;
  };
}
export default function FacebookLogin(): JSX.Element {
  const router = useRouter();
  const fbLocale = routerToFacebookLocale(router.locale);
  const [fbSdkLoaded, setFbSdkLoaded] = useState(false);
  function onStatusChange(response: AuthResponse): void {
    if (response && response.authResponse && response.authResponse.accessToken) window.open(`${config.website.url}/auth/callback?#access_token=${response.authResponse.accessToken}&returnTo=${encodeURIComponent(window.location.href)}`, '_self');
  }

  // Load Facebook SDK for Facebook Users
  useEffect(() => {
    if (fbSdkLoaded) {
      // OnInit
      try {
        if (!window.fbAsyncInit) {
          window.fbAsyncInit = function () {
            window.FB.init({
              appId: config.facebook.appId,
              cookie: true,
              xfbml: true,
              version: 'v12.0'
            });
            window.FB.Event.subscribe('auth.statusChange', onStatusChange);
          };
        } else {
          window.FB.init({
            appId: config.facebook.appId,
            cookie: true,
            xfbml: true,
            version: 'v12.0'
          });
          window.FB.Event.subscribe('auth.statusChange', onStatusChange);
        }
      } catch (error) {}
    } else {
      ;
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = `https://connect.facebook.net/${fbLocale}/sdk.js`;
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
      setFbSdkLoaded(true);
    }

    // OnUnMount
    return () => {
      try {
        window.FB.Event.unsubscribe('auth.statusChange');
      } catch (error) {}
    };
  }, [fbSdkLoaded]);
  return fbSdkLoaded ? <div className={container}>
      <div className="fb-login-button" data-size="large" data-button-type="continue_with" data-layout="rounded" data-auto-logout-link="false" data-use-continue-as="true"></div>
    </div> : <GsuLoader />;
}
const container = "c13qkzvq";

require("./index.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./index.tsx");