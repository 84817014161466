import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { Client } from 'urql'
import { initUrqlClient } from './initializeClient'

// Simple hook to initialize the client with the pageProps.
export const useClient = (pageProps: any): Client => {
  const router = useRouter()
  const urqlData = pageProps.URQL_DATA
  const { urqlClient } = useMemo(() => {
    return initUrqlClient({ initialState: urqlData, locale: router.locale })
  }, [urqlData, router.locale])
  return urqlClient
}
