const FacebookLocale: Record<string, string> = {
  'en-US': 'en_US',
  'hi-IN': 'hi_IN',
  'zh-CN': 'zh_CN',
  'es-US': 'es_LA',
}

const routerToFacebookLocale = (routerLocale = 'en-US'): string => {
  return FacebookLocale[routerLocale] ?? 'en_US'
}

export { routerToFacebookLocale }
