import { initUrqlClient } from '@/urql/initializeClient'
import * as Sentry from '@sentry/nextjs'
import { IncomingMessage } from 'http'
import { SSRData } from 'next-urql'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import { CombinedError, gql, OperationResult, TypedDocumentNode } from 'urql'

export interface QueryVariables {
  [name: string]: QueryVariables | any
}

/**
 * queryGsuApi
 *
 * Used for `getServerSideProps` and similar on any Page
 * to populate the page cache, and works in tandem with the
 * client registered `useQuery()` hook, which will hydrate
 * the store if no cached entry exists
 */
export async function queryGsuApi<T = any>(
  query: TypedDocumentNode,
  queryArgs?: QueryVariables,
  req?: IncomingMessage & {
    cookies: NextApiRequestCookies
  },
  locale?: string,
): Promise<[SSRData, number | null, T | null]> {
  let operationResult: OperationResult

  const { urqlClient, ssrCache } = initUrqlClient({
    req,
    locale,
  })

  if (queryArgs) {
    operationResult = await urqlClient.query(query, queryArgs).toPromise()
  } else {
    operationResult = await urqlClient.query(query, {}).toPromise()
  }

  if (operationResult.error) {
    const error = operationResult.error as CombinedError
    const { message } = error
    Sentry.captureException(`queryGsuApi Error: ${message}`, { extra: { error, operationResult }, level: 'error' })
    console.error(`[queryGsuApi] Error: ${message}`)
    console.error(operationResult)
  }

  return [
    ssrCache ? ssrCache.extractData() : {},
    getCombinedErrorStatusCode(operationResult.error),
    operationResult?.data,
  ]
}

export const getCombinedErrorStatusCode = (error?: CombinedError): number | null => {
  if (!error) return null
  const matches = /\d{3}/.exec(error?.message)
  const statusCode = matches ? +matches[0] : null
  return statusCode
}

export const QUERY_APP = gql`
  query AppOrPageBootstrap {
    currentUser {
      authenticatedUser {
        id
        email
        firstName
        lastName
        scopes
        isBanned
        isVerified
      }
      detectedCountryCode
      detectedCountryDialingCode
      detectedIPAddress
    }
    getsetupInfo {
      customerSupportContactNumber
    }
  }
`
