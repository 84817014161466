import * as Sentry from '@sentry/nextjs'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(relativeTime, {
  thresholds: [
    { l: 's', r: 1 },
    { l: 'ss', r: 59, d: 'second' },
    { l: 'm', r: 1 },
    { l: 'mm', r: 59, d: 'minute' },
    { l: 'h', r: 1 },
    { l: 'hh', r: 23, d: 'hour' },
    { l: 'd', r: 1 },
    { l: 'dd', r: 29, d: 'day' },
    { l: 'M', r: 1 },
    { l: 'MM', r: 11, d: 'month' },
    { l: 'y' },
    { l: 'yy', d: 'year' },
  ],
})

// dayjs Locale for relativeTime plugin
dayjs.extend(updateLocale)
dayjs.extend(duration)
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: '1m',
    mm: '%dm',
    h: '1hr',
    hh: '%dhr',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
})

type PossibleString = string | null | undefined

export const getTimeFromNow = (dateTime: PossibleString): string => {
  return dayjs(dateTime).fromNow()
}

export const getTzName = (): string => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (error) {
    console.log('Error retrieving timezone for client, defaulting to America/New_York')
    Sentry.captureException(error)
    return 'America/New_York'
  }
}

export const getDateTimeString = (dateTime: string): string => {
  return dayjs(dateTime).utc().format('YYYYMMDD[T]HHmmss[Z]')
}

export const getTimeDifference = (dateTime: string): string => {
  const startDate = dayjs(dateTime)
  return dayjs().from(startDate, true)
}

export const getDateTimeSeconds = (dateTime: string | undefined): string => {
  return dayjs(dateTime).unix().toString()
}

export const isSameDate = (dateOne: string, dateTwo: string): boolean => {
  return dayjs(dateOne).format('YYYY-MM-DD') === dayjs(dateTwo).format('YYYY-MM-DD')
}
export const dateWithFormat = (value: string | Date, format: string): string => {
  const date = dayjs(value)
  return date.format(format)
}
export const getDuration = (start: string, end: string): string => {
  const duration = dayjs.duration(dayjs(end).diff(dayjs(start)))
  const hours = duration.hours()
  const minutes = duration.minutes()
  //extra check for space between hours and minutes
  return `${hours > 0 ? hours + 'h' : ''}${minutes > 0 && hours > 0 ? ' ' : ''}${minutes > 0 ? minutes + 'm' : ''}`
}
