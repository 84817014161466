import React from 'react'
import * as styles from './style'

export interface Props {
  size?: number
  consumeSpace?: boolean
  className?: string
}

const Loader = ({ size = 50, className }: Props): JSX.Element => (
  <div className={`${styles.root} ${className}`} style={{ height: `${size}px`, width: `${size}px` }}>
    <span className={styles.brand1}></span>
    <span className={styles.brand2}></span>
  </div>
)

const GsuLoader = ({ consumeSpace = false, size, className = '' }: Props): JSX.Element =>
  consumeSpace ? (
    <div className={styles.consumeSpace}>
      <Loader size={size} className={className} />
    </div>
  ) : (
    <Loader size={size} className={className} />
  )
export default GsuLoader
