import Button from '@/components/Button'
import { Stack } from '@/components/Layout'
import Text, { Title } from '@/components/Text'
import { useModal } from '@/context/modal'
import { targetPageIdToUrl } from '@/lib/targetPageIdToUrl'
import { QUERY_APP } from '@/service/query'
import { Dialog, GetAccessSessionCardAction, GetsetupInfo } from '@/shared/api-generated-types'
import Modal from '@/widgets/Modal'
import RichText from '@/widgets/RichText'
import Lottie from 'lottie-web'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'urql'
import { QUERY_DIALOG } from './query'
import * as styles from './style'

export interface Props {
  staticDialog?: Dialog
  action?: Partial<GetAccessSessionCardAction>
}

const DialogAction = ({ action, staticDialog }: Props): JSX.Element | null => {
  const { t } = useTranslation('common')
  const [returnToUrl, setReturnToUrl] = useState<undefined | string>(undefined)
  const [ref, setRef] = useState<HTMLDivElement | null>(null)
  const {
    state: { isOpen },
    dispatch,
  } = useModal()

  const [{ data, fetching }] = useQuery<{ dialog: Dialog }>({
    query: QUERY_DIALOG,
    variables: {
      returnTo: returnToUrl,
      type: action?.dialogType,
    },
    requestPolicy: 'cache-first',
    pause: !action || !action?.dialogType,
  })

  useEffect(() => {
    setReturnToUrl(window.location.href)
  }, [])

  // We need to get getsetupInfo data to get the support phone number – let's fetch!
  const [{ data: siteData }] = useQuery<{ getsetupInfo: GetsetupInfo }>({
    query: QUERY_APP,
    requestPolicy: 'cache-first',
  })

  const dialog = data?.dialog || staticDialog

  useEffect(() => {
    if (ref) {
      Lottie.loadAnimation({
        name: 'dialog-animation',
        container: ref,
        renderer: 'canvas',
        loop: false,
        autoplay: true,
        path: dialog?.lottieJsonUrl,
      })
    }

    return () => {
      if (ref) {
        Lottie.destroy('dialog-animation')
      }
    }
  }, [ref])

  if (!dialog || fetching) return null // We don't display a button or dialog here, as it's likely there is no way to upgrade etc.

  return (
    <>
      {action?.label ? (
        <Button
          size="md"
          theme="primary"
          label={action.label}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            dispatch({ type: 'TOGGLE' })
          }}
        />
      ) : null}

      <Modal
        isOpen={isOpen}
        isClosable={dialog.closable}
        onClose={() => dispatch({ type: 'TOGGLE' })}
        onDismiss={() => {
          if (dialog.closable) {
            dispatch({ type: 'TOGGLE' })
          }
        }}
      >
        {dialog.videoUrl ? (
          <div className={styles.videoContainer}>
            <div>
              <video data-testid="video" controls poster={dialog.videoPosterUrl}>
                <source src={dialog.videoUrl} type="video/mp4" />
              </video>
            </div>
          </div>
        ) : null}

        <Stack
          y
          alignItems="center"
          spaceBetweenY="md"
          className={dialog.videoUrl ? styles.modalContentWithVideo : styles.modalContent}
        >
          {dialog.lottieJsonUrl && <div className={styles.heroAnimation} ref={(newRef) => setRef(newRef)} />}
          <div>
            {dialog.title ? (
              <Title size="lg" weight="lg" className={styles.title}>
                {dialog.title}
              </Title>
            ) : null}

            {dialog.supplementaryText ? (
              <Text size="xs" tag="p" weight="sm" className="pt-xxs">
                <span dangerouslySetInnerHTML={{ __html: dialog.supplementaryText }} />
              </Text>
            ) : null}
          </div>

          {dialog.partnerLogoUrl ? <img className={styles.heroAnimation} src={dialog.partnerLogoUrl} /> : null}

          <div className={styles.buttons}>
            {dialog.primaryAction && dialog.primaryAction.__typename === 'DialogPageAction' ? (
              <Button
                label={dialog.primaryAction.label}
                component="link"
                theme="primary"
                href={targetPageIdToUrl(dialog.primaryAction.targetPageId)}
                size="md"
                stretch
                tabIndex={1}
                className={styles.button}
              />
            ) : null}

            {dialog.primaryAction && dialog.primaryAction.__typename === 'DialogUrlAction' ? (
              <Button
                href={dialog.primaryAction.url}
                label={dialog.primaryAction.label}
                component="link"
                theme="primary"
                size="md"
                tabIndex={1}
                className={styles.button}
              />
            ) : null}

            {dialog.primaryAction && dialog.primaryAction.__typename === 'DialogFacebookConnectAction' ? (
              <Button
                href={dialog.primaryAction.url}
                label={dialog.primaryAction.label}
                component="link"
                iconLeft="facebook-filled"
                theme="facebookConnect"
                size="md"
                tabIndex={1}
                className={styles.button}
              />
            ) : null}

            {dialog.primaryAction && dialog.primaryAction.__typename === 'DialogDismissAction' ? (
              <Button
                label={dialog.primaryAction.label}
                component="link"
                theme="primary"
                size="md"
                className={styles.button}
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault()
                  dispatch({ type: 'DISMISS' })
                }}
              />
            ) : null}

            {dialog.secondaryAction && dialog.secondaryAction.__typename === 'DialogPageAction' ? (
              <Button
                label={dialog.secondaryAction.label}
                component="link"
                theme="link"
                href={targetPageIdToUrl(dialog.secondaryAction.targetPageId)}
                size="md"
                stretch
                tabIndex={2}
                className={styles.button}
              />
            ) : null}

            {dialog.secondaryAction && dialog.secondaryAction.__typename === 'DialogUrlAction' ? (
              <Button
                href={dialog.secondaryAction.url}
                label={dialog.secondaryAction.label}
                component="link"
                theme="primary"
                size="md"
                tabIndex={2}
                className={styles.button}
              />
            ) : null}

            {dialog.secondaryAction && dialog.secondaryAction.__typename === 'DialogDismissAction' ? (
              <Button
                label={dialog.secondaryAction.label}
                component="link"
                theme="link"
                size="md"
                className={styles.button}
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault()
                  dispatch({ type: 'DISMISS' })
                }}
              />
            ) : null}
          </div>

          {dialog.callout && (
            <div className={styles.callout}>
              <div className={styles.calloutIcon}>
                <img src={dialog.callout.icon} />
              </div>
              <RichText callout={dialog.callout} />
            </div>
          )}

          {dialog.hasHelpText ? (
            <div className={styles.help}>
              <Text size="xs" tag="p" weight="sm">
                {t('cta.needHelp')}
              </Text>
              <a href={`tel:${siteData?.getsetupInfo.customerSupportContactNumber}`}>
                <Text size="xs" tag="p" weight="md">
                  {siteData?.getsetupInfo.customerSupportContactNumber}
                </Text>
              </a>
            </div>
          ) : null}
        </Stack>
      </Modal>
    </>
  )
}
export default DialogAction
