import { useExternalToken } from '@/hooks/useExternalToken'

interface Props {
  children: JSX.Element
}

// Required to pass the external token to `eventsTracker` so that it's available for event tracking
export const ExternalAuthHandler = ({ children }: Props): JSX.Element => {
  useExternalToken()
  return children
}
