export const divider = "d1wv5w65";
export const heroAnimation = "h16y60zp";
export const title = "t1cpirn5";
export const modalContentWithVideo = "m7kisr6";
export const buttons = "bm5pxeq";
export const modalContent = "m8ojjza";
export const callout = "cgvpf1j";
export const calloutIcon = "cw8h0s8";
export const help = "h16vh9rn";
export const button = "b19sb80o";
export const videoContainer = "vf5vs9k";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");