export const disabled = "d14rchyi";
export const notDisabled = "nl5bmh4";
export const root = "r1k16bc6";
export const xs = "xmj6olu";
export const sm = "s2r4pwv";
export const md = "msm51bu";
export const lg = "l1ukzcpo";
export const xl = "x1jmpqzm";
export const primary = "pdta3yz";
export const facebookConnect = "fz3lqkv";
export const secondary = "sjc4li4";
export const tertiary = "t1cxxmz0";
export const primaryMuted = "p1bfa37s";
export const white = "w9u350s";
export const glass = "g1npqfl8";
export const smoke = "s1nlycv6";
export const green = "g1v5ke8y";
export const clear = "crfiisc";
export const link = "l1d0f7wg";
export const noBackground = "n1wl6tbu";
export const accent = "a1cgfs37";
export const primaryOutline = "p1yhwr1d";
export const primaryOutlineHover = "pj3oksm";
export const secondaryOutline = "s1lcp1jc";
export const whiteOutline = "wk67liw";
export const stretch = "s1pddbbv";
export const iconOnly = "ifx4mje";
export const sharpCorners = "s1cd4l3n";
export const textOnly = "t1gxvk1t";
export const textOnlySecondaryColor = "t1qa63kp";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");