/**
 * This code in this file must be compatible with the Edge Runtime because it is used by middleware.
 * Hence, it's separate from the runtime config.
 *
 * https://nextjs.org/docs/api-reference/edge-runtime
 */

// The values must be IETF Language Tags where the language is
// specific to a region/country.
//
// https://en.wikipedia.org/wiki/IETF_language_tag
// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes (2-letter)
// https://en.wikipedia.org/wiki/ISO_3166-1 (2-letter)
export enum Locale {
  'en-US' = 'en-US',
  'hi-IN' = 'hi-IN',
  // 'zh-CN' = 'zh-CN',
  // 'es-US' = 'es-US',
}

export type SupportedLocale = keyof typeof Locale

// This list should be ordered from most supported to least supported.
// If the user equally prefers two supported locales according to their accept-language header,
// we pick the one that is defined first in this list.
export const supportedLocales: SupportedLocale[] = ['en-US', 'hi-IN'] // 'zh-CN', 'es-US'
