import Dialog from '@/components/Dialog'
import FacebookLogin from '@/components/FacebookLogin'
import { useAuthPrompt } from '@/context/authPrompt'
import { toLoginAuthUrl } from '@/lib/authUrl'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import * as styles from './style'

export default function AuthDialog(): JSX.Element {
  const { t } = useTranslation('common')
  const router = useRouter()
  const { toggleAuthPrompt } = useAuthPrompt()

  function handleAuthClick(): void {
    window.location.href = toLoginAuthUrl(router.asPath)
    toggleAuthPrompt()
  }

  return (
    <Dialog customClass={styles.customClass}>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img src="/icons/chat.png" width="96px" />
        </div>
        <h1 className={styles.heading}>{t('authWelcomeTitle')} </h1>
        <h3 className={styles.subheading}>{t('authWelcomeSubtitle')}</h3>
        <div className={styles.buttonContainer}>
          <FacebookLogin />
          <br />
          <button className={styles.button} onClick={handleAuthClick}>
            {t('signUpSlashLogIn')}
          </button>
        </div>
      </div>
    </Dialog>
  )
}
