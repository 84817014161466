import IMoon, { IconProps } from 'react-icomoon'

const iconSet = require('./selection.json')

export type IconNames =
  | 'activity'
  | 'alarm'
  | 'alert'
  | 'apps'
  | 'arcade'
  | 'archive'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'audio-status-active'
  | 'audio-status'
  | 'award'
  | 'bell'
  | 'book'
  | 'box'
  | 'briefcase'
  | 'bulb'
  | 'calculator'
  | 'calendar'
  | 'camera-plus'
  | 'capture'
  | 'chat'
  | 'check'
  | 'checkbox-active'
  | 'checkbox'
  | 'checket'
  | 'checks'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'class'
  | 'click'
  | 'clipboard-check'
  | 'clipboard'
  | 'clock'
  | 'close'
  | 'community'
  | 'compass'
  | 'copy'
  | 'desktop'
  | 'devices'
  | 'dialog'
  | 'dice'
  | 'directions'
  | 'download'
  | 'drop-down'
  | 'drop-up'
  | 'duration'
  | 'ear'
  | 'edit'
  | 'encore'
  | 'enter'
  | 'explore'
  | 'exposure'
  | 'eye-off'
  | 'eye'
  | 'face-id'
  | 'facebook-empty'
  | 'facebook'
  | 'file-text'
  | 'file'
  | 'filters'
  | 'fingerprint'
  | 'flag'
  | 'flame'
  | 'flip-camera'
  | 'focus'
  | 'folder'
  | 'folders'
  | 'friends'
  | 'gamepad'
  | 'ghost'
  | 'gift'
  | 'glass-full'
  | 'globe'
  | 'grid-dots'
  | 'guide'
  | 'heart'
  | 'help'
  | 'history'
  | 'home'
  | 'info'
  | 'instagram'
  | 'introduction-view'
  | 'key'
  | 'language'
  | 'layout-horizontal'
  | 'layout-list'
  | 'layout-vertical'
  | 'leaf'
  | 'link'
  | 'linkedIn'
  | 'list-checks'
  | 'list'
  | 'live'
  | 'loader-1'
  | 'loader'
  | 'location'
  | 'lock-full'
  | 'lock-open-full'
  | 'lock-open'
  | 'lock'
  | 'login'
  | 'logout'
  | 'mail'
  | 'map-pin'
  | 'maximize'
  | 'menu'
  | 'mic-off'
  | 'mic'
  | 'minimize'
  | 'mobile'
  | 'more-actions'
  | 'move-btm'
  | 'move-top'
  | 'newbie'
  | 'news'
  | 'note'
  | 'notebook'
  | 'notes'
  | 'on-demand'
  | 'paperclip'
  | 'pause'
  | 'payment'
  | 'pen'
  | 'people-add'
  | 'phone-call'
  | 'photo'
  | 'pinned'
  | 'plans'
  | 'play-full'
  | 'play'
  | 'plus'
  | 'pop-Out'
  | 'popout'
  | 'popout2'
  | 'presentation'
  | 'puzzle'
  | 'question'
  | 'radio-off'
  | 'radio-on'
  | 'react'
  | 'reset'
  | 'resize-maximise'
  | 'resize-minimise'
  | 'retry'
  | 'room'
  | 'rotate'
  | 'sad'
  | 'school'
  | 'screen-share-off'
  | 'search'
  | 'selector'
  | 'send'
  | 'series'
  | 'settings'
  | 'shapes'
  | 'share-screen'
  | 'share'
  | 'shield-check'
  | 'smile'
  | 'speakerphone'
  | 'star-empty'
  | 'star-full'
  | 'stop'
  | 'swap'
  | 'switch--camera'
  | 'switch--camera1'
  | 'tablet'
  | 'tag'
  | 'trash'
  | 'trophy'
  | 'twitter-full'
  | 'twitter'
  | 'upload'
  | 'user-check'
  | 'user'
  | 'users'
  | 'video-off'
  | 'video'
  | 'view-columns'
  | 'view-gallery'
  | 'view-one-to-one-off'
  | 'view-one-to-one'
  | 'view-speaker'
  | 'view-stage'
  | 'volume-off'
  | 'volume'
  | 'window'
  | 'youtube-full'
  | 'youtube'
  | 'on-demand'

interface IcoMoonProps extends IconProps {
  icon: IconNames
}

const IcoMoon: React.FC<IcoMoonProps> = (props) => <IMoon iconSet={iconSet} {...props} />

export default IcoMoon
