import { eventsTracker } from '@/lib/eventsTracker'
import { firstStringVal } from '@/lib/queryString'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

export function useExternalToken(): {} {
  const router = useRouter()
  const [embeddedOrgId, setEmbeddedOrgId] = useState<string | undefined>(undefined)
  const [embeddingToken, setEmbeddingToken] = useState<string | undefined>(undefined)

  useEffect(() => {
    const embeddedOrgIdFromQueryParam = firstStringVal(router.query['embedding-org-id'])
    const embeddedDeviceIdFromQueryParam = firstStringVal(router.query['device-id'])

    // We use the OrgId later, so store it now.
    setEmbeddedOrgId(embeddedOrgIdFromQueryParam)

    // If not inside an embed, exit early as the default `eventsTracker.configureOptions` is what we want.
    if (!embeddedOrgIdFromQueryParam || !embeddedDeviceIdFromQueryParam || embeddedOrgIdFromQueryParam !== 'aol') {
      return
    }

    // If we are running inside an embed, configure the eventsTracker with the embedded options.
    // NOTE: On initial load of the `eventsTracker` lib, we set the `embeddedOptions` from the query params.
    eventsTracker.configureOptions({
      embeddedOptions: {
        identifier: embeddedOrgIdFromQueryParam,
        deviceIdOverride: embeddedDeviceIdFromQueryParam,
        // May be undefined if called before post-message
        // dance has been completed with embedded partner.
        token: embeddingToken,
      },
    })
  }, [embeddingToken, router.query])

  const receiveToken = useCallback(
    (event: MessageEvent<any>) => {
      // TODO: also check the message origin
      if (embeddedOrgId && event.data?.gsuTokenFromHost) {
        const encodedToken = `${embeddedOrgId}:${event.data?.gsuTokenFromHost}`
        setEmbeddingToken(encodedToken)
      }
    },
    [embeddedOrgId],
  )

  const requestToken = (): void => {
    const messageFromIframe = { gsuTokenRequest: true }
    // TODO: use a better message destination
    window.parent.postMessage(messageFromIframe, '*')
  }

  useEffect(() => {
    if (embeddedOrgId === 'aol') {
      // Add an event listener for receive tokens from the page embedding this
      // and then send the request for a token to the parent page.
      window.addEventListener('message', receiveToken)
      requestToken()
      return () => {
        window.removeEventListener('message', receiveToken)
      }
    }
  }, [receiveToken, embeddedOrgId])

  return {}
}
